const defaultDashboard = [
  {
    id: 0,
    name: 'Métricas críticas del negocio',
    description: 'Un panel de control en tiempo real para comprender las métricas críticas relacionadas con el crecimiento, la retención y las fugas y para mantenerse al tanto del crecimiento del negocio en los tiempos inciertos de COVID-19',
    type: [],
    category: 'executive',
    url: 'CollectiontitleList',
    active: true
  },
  {
    id: 1,
    name: 'Vigilancia de finanzas',
    description: 'Este panel le ayuda a comprender cuántos ingresos está generando su organización en comparación con MRR, que es un valor teórico. Con las métricas de este panel también puede obtener visibilidad de su flujo de caja, pagos y reembolsos, y el vencimiento de su tarjeta de crédito',
    type: [],
    category: 'marketing',
    url: 'CollectiontitleList',
    active: true
  },
  {
    id: 2,
    name: 'Aspectos destacados de los negocios',
    description: 'Proporciona una vista de pájaro de todas las métricas clave que giran en torno a MRR, Crecimiento, Suscripciones y Churn, mensual y trimestralmente. Este panel le ayuda a comprender el estado de su negocio y ver si está en el buen camino. Es útil para los miembros en funciones ejecutivas u operativas, para comparar el desempeño de su mes y trimestre anterior con el mes y trimestre anterior, y levantar banderas rojas temprano.',
    type: [],
    category: 'marketing',
    url: 'CollectiontitleList',
    active: true
  },
  {
    id: 3,
    name: 'Vigilancia ejecutiva',
    description: 'Ofrece tendencias históricas con métricas en torno a MRR, MRR Growth, Retention y Churn desglosadas en el transcurso de varios meses. Este panel proporciona información sobre su rendimiento actual, en comparación con las mismas métricas del mes anterior. Utilícelo para obtener una visión detallada de su crecimiento para las partes interesadas y los inversores',
    type: [],
    category: 'marketing',
    url: 'CollectiontitleList',
    active: true
  },
  {
    id: 4,
    name: 'Vigilancia de marketing',
    description: 'Muestra métricas que afectan a la parte superior del embudo de conversión, como registros, activaciones y nuevos ingresos. Este panel también incluye información sobre más actividades a largo plazo, como la retención de suscripciones y las cohortes de crecimiento de MRR, y la expansión neta del dólar que permiten a los equipos de marketing ver el impacto de sus estrategias de adquisición durante un período de tiempo más largo e identificar los mercados emergentes',
    type: [],
    category: 'finance',
    url: 'CollectiontitleList',
    active: true
  },
  {
    id: 5,
    name: 'Vigilancia de ventas',
    description: 'Consta de las principales métricas de ingresos como New MRR, Expansion MRR, Upgrade MRR y ARR, segmentadas por planes, territorios geográficos y verticales. Este panel permite al equipo de ventas identificar qué industrias, geografías, planes e incluso representantes de ventas contribuyen a la mayor cantidad de ingresos',
    type: [],
    category: 'sales',
    url: 'CollectiontitleList',
    active: true
  },
  {
    id: 6,
    name: 'Reloj de éxito del cliente',
    description: 'Realiza un seguimiento de las métricas de interacción, retención y crecimiento de su negocio de suscripción entre clientes e ingresos. Este panel muestra la suscripción y la renovación de ingresos, la actualización y la degradación de MRR, la tasa de abandono neto y una vista cohortizada de cómo los clientes se están revolciendo, además de un desglose basado en el tiempo de cuándo los clientes se reparten. Utilícelo para supervisar y realizar un seguimiento de sus iniciativas de éxito de clientes.',
    type: [],
    category: 'customerSuccess',
    url: 'CollectiontitleList',
    active: true
  },
  {
    id: 7,
    name: 'Reloj mensual',
    description: 'Mira todos los componentes que afectan a tu MRR mes a mes, como New MRR, MRR de conversiones gratuitas a pagadas, expansiones y la pérdida debida a cancelaciones, degradaciones y cancelaciones programadas. Este panel también le ofrece una idea de sus cargos medidos, LTV y CAC, y la rotación mensual tanto en términos de número de clientes como de valor en dólares perdidos',
    type: [],
    category: 'sales',
    url: 'CollectiontitleList',
    active: true
  },
  {
    id: 8,
    name: 'Vigilancia de suscripciones',
    description: 'Muestra una colección de todos los KPI principales para proporcionar una perspectiva holística del ciclo de vida de su suscripción, como inscripciones, activaciones, abandono, etc. Utilice este panel para comprender cómo han evolucionado las métricas de su organización semanalmente o mensualmente. Este panel es especialmente útil para comparar las tendencias dinámicas y a largo plazo de sus suscripciones simultáneamente.',
    type: [],
    category: 'customerSuccess',
    url: 'CollectiontitleList',
    active: true
  }
]

export default defaultDashboard